/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { ActiveCallsList } from './ActiveCallsList';

import { getActiveCalls, getLoading, getLoaded } from '../state/selectors';
import { useFetchActiveCalls } from '../state/hooks';

export const ActiveCalls = () => {
  const data = useSelector(getActiveCalls);
  const isLoading = useSelector(getLoading);
  const loaded = useSelector(getLoaded);
  const isMounted = useRef(false);

  useFetchActiveCalls(isMounted);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  return (
    <LoadingContainer loading={isLoading && loaded === false}>
      <ActiveCallsList data={data} />
    </LoadingContainer>
  );
};
