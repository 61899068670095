import React from 'react';
import clsx from 'clsx';
import { withStyles, Paper, makeStyles } from '@material-ui/core';

const StyledPaper = withStyles(theme => ({
  root: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2),
    },
  },
  rounded: {
    borderRadius: 5,
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 0),
  },
}));

export default StyledPaper(props => {
  const { className } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <Paper {...props} elevation={0} />
    </div>
  );
});
