/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGroupId } from './selectors';
import { actions } from './slice';

export const useFetchActiveCalls = (isMounted) => {
  const dispatch = useDispatch();
  const groupId = useSelector(getGroupId);

  const [timer, setTimer] = useState(false);
  const [interval, setInterval] = useState(1000);
  const [checkIsHidden, setCheckIsHidden] = useState(false);
  let timerId = null;

  timerId = setTimeout(() => {
    if (!isMounted || !isMounted.current) {
      clearTimeout(timerId);
      return;
    }

    if (document.hidden) {
      setInterval(1000);
      setCheckIsHidden(!checkIsHidden);
      return;
    }

    setInterval(5000);
    setTimer(!timer);
    clearTimeout(timerId);
  }, interval);

  useEffect(() => {
    dispatch(actions.getActiveCalls({ group_id: groupId, page: 1 }));
  }, [dispatch, groupId, timer]);

  useEffect(() => {
    dispatch(() => { });
  }, [dispatch, groupId, checkIsHidden]);
};
