/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';

import Paper from 'src/components/paper';
import InfoCard from 'src/components/infoCard';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
  },
  root: {
    minHeight: 250,
    position: 'relative',
  },
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {},
}));

export const ActiveCallsList = ({ data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          Active Calls
        </Typography>
      </div>

      {
        data.length === 0
          ? (
            <div className={classes.container}>
              <Typography variant="body1">No records to display</Typography>
            </div>
          )
          : (
            <Grid container spacing={2}>
              {
                data.map(({ id, source, lead, rep, initiated, groupName }) => (
                  <Grid key={id} item xs={12} md={4}>
                    <InfoCard source={source} lead={lead} rep={rep} time={initiated} groupName={groupName}/>
                  </Grid>
                ))
              }
            </Grid>
          )
      }
    </Paper>
  );
};
