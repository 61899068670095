import React from 'react';

import SEO from 'src/components/seo';
import { ActiveCallsList } from 'src/features/activeCalls';

const ActiveCalls = () => {
  return (
    <>
      <SEO title="Active calls" />

      <ActiveCallsList />
    </>
  );
};

export default ActiveCalls;
