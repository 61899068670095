import { activeCallsSelectors } from './slice';

export const getPage = state => state.activeCalls;

export const getGroupId = state => state.global.groupList.selectedGroup;

export const getLoading = state => state.activeCalls.loading;

export const getLoaded = state => state.activeCalls.loaded;

export const getError = state => state.activeCalls.error;

export const getActiveCalls = state => activeCallsSelectors.selectAll(state.activeCalls.list);
