import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    borderRadius: 5,
    backgroundColor: '#f2f2f2',
  },
  container: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
  },
  leftContainer: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  rightContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    fontSize: 18,
    fontWeight: 700,
  },
  subTitle: {
    color: '#9a9ba5',
  },
  timeText: {
    fontSize: 14,
    fontWeight: 700,
  },
  nameText: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 700,
  },
}));

export default function InfoCard({ source, lead, rep, time, groupName }) {
  const classes = useStyles();
  let leadPhone, repPhone;
  if (lead && lead.phone) {
    leadPhone = phoneFormatter(lead.phone);
  }
  if (rep && rep.phone) {
    repPhone = phoneFormatter(rep.phone);
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <Typography className={classes.subTitle}>Lead Name:</Typography>
          <Typography className={classes.nameText} gutterBottom>
            {lead.name}
          </Typography>
          <Typography className={classes.subTitle}>Lead Phone:</Typography>
          <Typography className={classes.phoneText}>{leadPhone}</Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography className={classes.subTitle}>Rep Name:</Typography>
          <Typography className={classes.nameText} gutterBottom>
            {rep.name}
          </Typography>
          <Typography className={classes.subTitle}>Rep Phone:</Typography>
          <Typography className={classes.phoneText}>{repPhone}</Typography>
        </div>
      </div>
      <Typography className={classes.subTitle} gutterBottom>
        Group name:{' '}
        <Typography color="textPrimary" component="span" className={classes.timeText}>
          {groupName ? groupName : 'Not Provided'}
        </Typography>
      </Typography>
      {/* <Typography className={classes.subTitle} gutterBottom>
        Lead Source:{' '}
        <Typography color="textPrimary" component="span" className={classes.timeText}>
          {source ? source : 'Not Provided'}
        </Typography>
      </Typography> */}
      <Typography className={classes.subTitle}>
        Date and time a call initiated to rep:{' '}
        <Typography color="textPrimary" component="span" className={classes.timeText}>
          {time}
        </Typography>
      </Typography>
    </div>
  );
}

InfoCard.propTypes = {
  // source: PropTypes.string.isRequired,
  lead: PropTypes.shape({ name: PropTypes.string, phone: PropTypes.string }).isRequired,
  rep: PropTypes.shape({ name: PropTypes.string, phone: PropTypes.string }).isRequired,
  time: PropTypes.string.isRequired,
};
